import '../App.css';
import Navbar from '../components/Navbar/Navbar'
import Question from '../components/Question';
import React from 'react';


function Questionview() {
  // usestate count
  return (
    <div>
      <Navbar />
      <Question />
    </div>
  );
}

export default Questionview;

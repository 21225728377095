import React, { useState, useEffect } from "react";
import GraphComponents from "../components/BMRComponent/GraphComponent/GraphComponents";

export default function GraphView() {
  const [selectedCity, setSelectedCity] = useState("Istres");


  useEffect(() => {
    // Mettre à jour le composant GraphComponents à chaque fois que la ville sélectionnée change    
  }, [selectedCity]);

  return (
    <div style={styles.mainpage}>
      <select
        value={selectedCity}
        onChange={(e) => setSelectedCity(e.target.value)}
        style={styles.selectbutton}
      >
        <option value="Choisir">Choisir</option>
        <option value="Brest">Brest</option>
        <option value="Bordeaux">Bordeaux</option>
        <option value="Cazaux">Cazaux</option>
        <option value="Mont-de-Marsan">Mont-de-Marsan</option>
        <option value="Toulouse">Toulouse</option>
        <option value="Istres">Istres</option>
        <option value="Aix-en-Provence">Aix-en-Provence</option>
      </select>
      <div styles={{paddingTop: "90%"}}>
      <h2> Moyen General d'etat de santé des Controllers</h2> 
      </div>  
      <GraphComponents city={selectedCity} />
    </div>
  );
}

const styles = {
  textmain: {
    marginTop: "10vh",
    textAlign: "center",
  },
  selectbutton: {
    
    margin: "2%",
  },
  buttonconfirm: {
    backgroundColor: "#1e90ff",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    margin: "10px",
  },
  mainpage: {
    backgroundColor: "#282c34",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    color: "white",
  },
};

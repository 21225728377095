import ListGroup from 'react-bootstrap/ListGroup';

function ButtonAnswer(QuestionAndAnswer) {
    //display all the answers of the Question as a button

    function handleSubmit(e) {
        console.log(e, "<-- e")

        for (let a = 0, b = 0; a < QuestionAndAnswer.QuestionAndAnswer.Answer.length; a++, b++) {

            if (e === QuestionAndAnswer.QuestionAndAnswer.Answer[a]) {

                localStorage.setItem(QuestionAndAnswer.QuestionAndAnswer.Question, b)
                
                console.log("Question Button", QuestionAndAnswer.QuestionAndAnswer.Question, "reponse", b)

            }
        }
    }
    return (

        <div>
            {QuestionAndAnswer.QuestionAndAnswer.Answer.map((reponse, index) => {
                let itemColor;
                let textColor;
                if (index === 0) {
                    itemColor = 'green';
                    textColor = 'white'; // texte noir pour le vert
                } else if (index === QuestionAndAnswer.QuestionAndAnswer.Answer.length - 1) {
                    itemColor = 'red';
                    textColor = 'white'; // texte blanc pour le rouge
                } else if (index === QuestionAndAnswer.QuestionAndAnswer.Answer.length - 2) {
                    itemColor = 'orange';
                    textColor = 'white'; // texte noir pour l'orange
                } else {
                    itemColor = 'yellow';
                    textColor = 'black'; // texte noir pour le jaune
                }
                return (
                    <ListGroup.Item style={{ ...styles.button, backgroundColor: itemColor, color: textColor }} action onClick={() => handleSubmit(reponse)} variant="primary">
                        {reponse}
                    </ListGroup.Item>
                );
            })}
        </div>
    );
}



const styles = {
    button: {
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        margin: '10px'
    },
    divcenter: {
        display: 'flex', justifyContent: 'center', alignItems: 'center'
    }
}

export default ButtonAnswer;
